import request from '@/utils/request'
import {compileScript} from "@vue/compiler-sfc";
// 创建角色
export const createDentistTeam: any = (data: any) => {
    console.log(data)
    return request({
        url: '/dentist/team',
        method: 'post',
        data
    })
}

export const searchDentistTeam: any = (data: any) => {
    return request({
        url: '/dentist/team/search',
        method: 'post',
        data
    })
}

export const updateDentistTeam: any = (data: any) => {
    return request({
        url: '/dentist/team',
        method: 'put',
        data
    })
}

export const getDentistTeam: any = (id: any) => {
    return request({
        url: `/dentist/team/${id}`,
        method: 'get',
    })
}

export const deleteDentistTeam: any = (id: number) => {
    return request({
        url: `/dentist/team/${id}`,
        method: 'delete',
    })
}

export const searchTeamMembers: any = (data: any) => {
    return request({
        url: `/dentist/searchTeamMembers`,
        method: 'post',
        data
    })
}