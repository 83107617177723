import request from '@/utils/request'
// 创建角色
export const createSysRole:any = (data: any) => {
    return request({
      url: '/sysRole',
      method: 'post',
      data
    })
  }

export const searchSysRole:any = (data:any) => {
   return request({
      url:'/sysRole/search',
      method:'post',
      data
   })
}

export const updateSysRole:any = (data:any) => {
    return request({
        url:'/sysRole',
        method:'put',
        data
    })
}

// 根据id查询医生
export const getSysRoleId:any = (id: any) => {
  return request({
    url: `/sysRole/${id}`,
    method: 'get',
  })
}

export const deleteSysRole:any = (id:number) =>{
  return request({
    url: `/sysRole/${id}`,
    method: 'delete',
  })
}

export const setSysRoleMenu:any = (data:any) => {
  return request({
    url: '/sysRoleMenu',
    method: 'post',
    data
  })
}

export const updateSysRoleMenu:any = (data:any) => {
  return request({
    url: '/sysRoleMenu',
    method: 'put',
    data
  })
}

export const getSysRoleMenuInfo:any = (id:any) => {
  return request({
    url: `/sysRoleMenu/${id}`,
    method: 'get',
  })
}