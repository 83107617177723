import request from '@/utils/request'


export const producerTeamList: any = (data: any) => {
    console.log(data)
    return request({
        url: '/producer/productTeamList',
        method: 'post',
        data
    })
}

export const producerTeam: any = (data: any) => {
    console.log(data)
    return request({
        url: '/producer/productTeam',
        method: 'post',
        data
    })
}

export const getMembersById: any = (id: string) => {
    return request({
        url: `/producer/teamMembers/${id}`,
        method: 'get',
    })
}

export const pageMembers: any = (data: any) => {
    return request({
        url: `/producer/pageListProducers`,
        method: 'post',
        data
    })
}

export const getById: any = (id: string) => {
    return request({
        url: `/producer/team/${id}`,
        method: 'get',
    })
}

export const insertProducer: any = (data: any) => {
    return request({
        url: `/producer/`,
        method: 'post',
        data
    })
}
export const updateProducer: any = (data: any) => {
    return request({
        url: `/producer/`,
        method: 'put',
        data
    })
}

export const getProducerById: any = (id: string,teamId: string) => {
    return request({
        url: `/producer/${id}/${teamId}`,
        method: 'get',
    })
}