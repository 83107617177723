import request from '@/utils/request'

// 查询机构
export const searchPartner:any = (data: any) => {
  console.log(data)
    return request({
      url: `/partner/search`,
      method: 'post',
      data
    })
  }
// 添加机构
export const addPartner:any = (data: any) => {
    return request({
      url: `/partner`,
      method: 'post',
      data
    })
  }

// 修改机构
export const updatePartner:any = (data: any) => {
    return request({
      url: `/partner`,
      method: 'put',
      data
    })
  }

// 修改机构
export const getPartner:any = (id: any) => {
  return request({
    url: `/partner/${id}`,
    method: 'get',
  })
}


//-------------------------职位-------------------------

// 查询当前机构职业
export const getPosition:any = (id: any) => {
  return request({
    url: `/position/${id}`,
    method: 'get',
  })
}

// 添加职位
export const addPosition:any = (data: any) => {
  return request({
    url: `/position`,
    method: 'post',
    data
  })
}

//-----------------人员---------------------------------

// 查询当前职业下的人员
export const searchPositionStaff:any = (data: any) => {
  return request({
    url: `/employee/search`,
    method: 'post',
    data
  })
}

// 添加人员
export const addPositionStaff:any = (data: any) => {
  return request({
    url: `/employee`,
    method: 'post',
    data
  })
}

// 修改人员
export const updatePositionStaff:any = (data: any) => {
  return request({
    url: `/employee`,
    method: 'put',
    data
  })
}
// 获取人员信息详情
export const getPositionStaff:any = (id: any,partnerId: any) => {
  return request({
    url: `/employee/${id}/${partnerId}`,
    method: 'get',
  })
}

// 删除该人员
export const deletePositionStaff:any = (id: any,partnerId: any) => {
  return request({
    url: `/employee/removeEmployee/${id}/${partnerId}`,
    method: 'delete',
  })
}
