import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-465bcfb5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "warp-left" }
const _hoisted_2 = { class: "warp-conen" }
const _hoisted_3 = { class: "warp-right" }
const _hoisted_4 = { class: "carousel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadImage = _resolveComponent("UploadImage")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.person,
          "label-col": _ctx.labelCol,
          "wrapper-col": _ctx.wrapperCol,
          autoComplete: "off",
          rules: _ctx.rules,
          ref: "createProducerRef"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_UploadImage, {
                  onOnSuccess: _cache[0] || (_cache[0] = (url) => _ctx.onSuccess(url)),
                  imageUrl: _ctx.data.avatarUrl.path
                }, null, 8, ["imageUrl"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_form_item, {
                  label: "姓名",
                  name: "name"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _ctx.person.name,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.name) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "生日" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_date_picker, {
                      value: _ctx.person.born,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.person.born) = $event)),
                      "disabled-date": _ctx.disabledDate,
                      valueFormat: "YYYY-MM-DD"
                    }, null, 8, ["value", "disabled-date"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "性别" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      value: _ctx.person.gender,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.person.gender) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: "Male" }, {
                          default: _withCtx(() => [
                            _createTextVNode("男")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: "Female" }, {
                          default: _withCtx(() => [
                            _createTextVNode("女")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: "手机号",
                        name: "mobile"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            disabled: !!_ctx.id,
                            value: _ctx.person.mobile,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.person.mobile) = $event))
                          }, null, 8, ["disabled", "value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: "邮箱",
                        name: "email"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: _ctx.person.email,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.person.email) = $event)),
                            autocomplete: "off"
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: "职位",
                        name: "positionId"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_tree_select, {
                            value: _ctx.data.formState.positionId,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.formState.positionId) = $event)),
                            "show-search": "",
                            style: {"width":"100%"},
                            onFocus: _ctx.selectPositions,
                            "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                            placeholder: "Please select",
                            "allow-clear": "",
                            "tree-default-expand-all": "",
                            "tree-data": _ctx.data.treeData,
                            "field-names": {
                                        children: 'children',
                                        title: 'name',
                                        value: 'id',
                                    }
                          }, {
                            title: _withCtx(({ name }) => [
                              _createElementVNode("span", null, _toDisplayString(name), 1)
                            ]),
                            _: 1
                          }, 8, ["value", "onFocus", "tree-data"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_a_form_item, { label: "登录密码" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input_password, {
                            value: _ctx.data.formState.password,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.formState.password) = $event)),
                            placeholder: "密码默认为手机号后六位",
                            autocomplete: "new-password"
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 512), [
                        [_vShow, !_ctx.data.producerId]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 12 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { label: "是否负责人" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_switch, {
                            "checked-children": "是",
                            "un-checked-children": "否",
                            checked: _ctx.data.formState.teamLeader,
                            "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.formState.teamLeader) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "label-col", "wrapper-col", "rules"])
      ]),
      _: 1
    })
  ]))
}