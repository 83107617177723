
import dayjs, {Dayjs} from "dayjs";
import {defineComponent, onMounted, reactive, ref, UnwrapRef,} from "vue";
import UploadImage from "@/components/view/UploadImage.vue";
import { getPosition} from "@/api/doctor";
import {message} from "ant-design-vue";
import {Rule} from "ant-design-vue/lib/form";
import STATUS from "@/components/model/enum";
import {getDate} from "@/components/ts/components";
import {isEmail, isMobile} from "@/utils/validate";
import {getDesignerById, insertDesigner, updateDesigner} from "@/api/designer";

interface Data {
    formState: {
        password: string;
        id: string;
        teamId: string;
        positionId: string;
        teamLeader:boolean | any;
    };
    avatarUrl: any;
    disabled: boolean;
    designerId: number;
    treeData: any[];

}

interface Person {
    name: string;
    email: string;
    mobile: string;
    gender: string;
    born: string;
    idNumber: string;
    id: string;
    owner: string
}

let timeout: any;
let currentValue = "";


export default defineComponent({
    components: {
        UploadImage,
    },
    props: ["id", "type", "teamId", "treeData"],
    emits: ["onCancel"],
    mounted() {
        this.selectPositions()
    },
    setup(props, context) {

        const person: UnwrapRef<Person> = reactive({
            name: "",
            email: "",
            mobile: "",
            gender: "Male",
            born: "",
            idNumber: "",
            id: "",
            owner: ""
        });
        const data: UnwrapRef<Data> = reactive({
            formState: {
                password: "",
                teamId: props.teamId,
                id: "",
                positionId: "",
                teamLeader: false,
            },
            avatarUrl: {
                title: "头像",
                path: "",
                type: "AVATAR",
                id: ''
            },
            disabled: false,
            designerId: 0,
            treeData: []
        });
        let validate = async (value: string, message: string) => {
            console.log(data.formState[value as keyof typeof data.formState]);
            if (!data.formState[value as keyof typeof data.formState]) {
                return Promise.reject(message);
            } else {
                return Promise.resolve();
            }
        };
        const rules: Record<string, Rule[]> = {
            name: [
                {
                    required: true,
                    trigger: "change",
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.reject("请输入姓名!");
                        }
                        if (value.length > 32 || value.length < 2) {
                            return Promise.reject("姓名长度应为2-32个字!");
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ],
            // mobile: [{ required: true,message: '请填写号码!', trigger: 'change' }],
            mobile: [
                {
                    required: true,
                    trigger: "change",
                    validator: (rule, value) => {
                        if (value == "") {
                            return Promise.reject("请填写手机号!");
                        }
                        if (!isMobile(value)) {
                            return Promise.reject("手机号格式错误!");
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ],
            email: [
                {
                    type: "email",
                    required: false,
                    trigger: "change",
                    validator: (rule, value) => {
                        if (value == "") {
                            return Promise.resolve();
                        }
                        if (!isEmail(value)) {
                            return Promise.reject("邮箱格式错误!");
                        } else {
                            return Promise.resolve();
                        }
                    },
                },
            ],
            password: [
                {
                    required: true,
                    validator: () => validate("password", "请输入密码!"),
                    trigger: "change",
                },
            ],
            positionId: [
                {
                    required: true,
                    validator: () => validate("positionId", "请选择职位!"),
                    trigger: "change",
                },
            ],
        };
        const createDesignerRef = ref();
        // 新建设计师
        const onSubmit = () => {
            console.log('新建');
            createDesignerRef.value.validate().then(() => {
                const option = {
                    ...person,
                    ...data.formState,
                    designerTeamId: props.teamId,
                    docs: [data.avatarUrl],
                };
                console.log(option);

                if (props.type == 'update') {
                    update(option);
                } else {
                    add(option);
                }
                console.log("submit!", option);
            }).catch(() => {
                // context.emit("onCancel", false);
            })
        };
        const add = (option: any) => {
            insertDesigner(option).then(() => {
                message.success("创建成功");
                createDesignerRef.value?.resetFields();
                context.emit("onCancel", true);
            }).catch(() => {
                // context.emit("onCancel", false);
            })
        }

        const update = (option: any) => {
            console.log("修改设计师");
            option.id = props.id
            updateDesigner(option).then(() => {
                console.log('修改设计师成功');
                createDesignerRef.value?.resetFields();
                context.emit("onCancel", true);
            }).catch(() => {
                // context.emit("onCancel", false);
            })
        }
        const selectPositions = () => {
            console.log("查询职位");
            getPosition(props.teamId).then((res: any) => {
                data.treeData = res
            }).catch(() => {
                // context.emit("onCancel", false);
            })
        }
        // 不可选日期设置
        const disabledDate = (current: Dayjs) => {
            // Can not select days before today and today
            return current && current > dayjs().endOf("day");
        };
        const init = async () => {
            console.log(props);
            if (props.id) {
                data.disabled = true;
                data.designerId = props.id;
                const res = await getDesignerById(props.id,props.teamId);
                console.log(res);
                for (const key in person) {
                    person[key as keyof typeof person] = res[key];
                }
                for (const key in data.formState) {
                    data.formState[key as keyof typeof data.formState] = res[key];
                }
                person.born = res.born ? getDate(res.born) : "";
                data.avatarUrl = res.docs[res.docs.length - 1];
                console.log(data.avatarUrl)
            }
        };
        const onSuccess = (url: string) => {
            data.avatarUrl.path = url;
        };
        onMounted(async () => {
            await init();
        });
        return {
            labelCol: {style: {width: "100px"}},
            wrapperCol: {span: 14},
            person,
            createDesignerRef,
            rules,
            onSubmit,
            disabledDate,
            onSuccess,
            data,
            STATUS,
            selectPositions,
        };
    },
});
